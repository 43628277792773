@font-face {
  font-family: 'Avenir Next';
  src: url('./assets/fonts/AvenirNext-Regular.otf')format('opentype');
}

@font-face {
  font-family: 'Avenir Next Italic';
  src: url('./assets/fonts/AvenirNext-Italic.otf')format('opentype');
}

@font-face {
  font-family: 'Avenir Next Bold';
  src: url('./assets/fonts/AvenirNext-Bold.otf')format('opentype');
}

@font-face {
  font-family: 'Avenir Next Bold Italic';
  src: url('./assets/fonts/AvenirNext-Bold-Italic.otf')format('opentype');
}

body {
  margin: 0;
  font-family: 'Avenir Next', sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
