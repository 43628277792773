table {
    font-family: "Avenir Next", sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin: 24px 0 48px 0;
}

td, th {
    border: 1px solid #000;
    text-align: left;
    padding: 10px 12px 10px 12px;
    font-size: 16px;
}

th {
    background-color: #dddddd;
    font-weight: bold;
}

.inline-pre {
    display: inline;
}

.bordered-code {
    background-color: #eee;
    border: 1px solid #999;
    display: inline;
    padding: 2px;
    width: fit-content;
}

.inline-code {
    background-color: #eee;
    border: 1px #999;
    display: inline;
    padding: 1px;
    width: fit-content;
    line-height: 18pt;
}